<template>
  <div class="max-w-screen-lg bg-gray-800 text-white">
    <div class="content">
      <h1>
        About
      </h1>
      <p>
        The Connecting Wall is a puzzle game from the BBC quiz show <i>Only Connect</i>.
        Contestants are given a wall of 16 clues that can be sorted into four groups of four connected items.
        Some clues could fit in more than one group, but there is only one perfect solution.
        The goal is to find the four unique connections before time runs out.
      </p>
      <p>
        You can select and unselect clues by clicking/tapping on them.
        Once you've selected four clues, your selection will be automatically checked.
	<!--
        After finding two groups, each incorrect selection will count as a strike.
        Three strikes and it's game over!
	-->
      </p>
      <hr class="my-6">
      <p>
        This project is not affiliated with <i>Only Connect</i> or the BBC in any way.
      </p>
    </div>
  </div>
</template>
